import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Requests from '../scripts/requests'
import { Purchase } from '../scripts/types'
import CircleLoading from '../components/CircleLoading'
import _ from 'lodash'

import "../css/PurchaseHistory.scss"
import { dateFormat } from '../scripts/utils'
import { useCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'

function PurchaseHistory() {

    const dispatcher = useDispatch()
    const [dataLoading, setDataLoading] = useState(true)
    const [itemsPurchased, setItemsPurchased] = useState<Purchase[]>([])

    const searchInput = useRef<any>()
    const [search, setSearch] = useState<string>("")
    const [cookies, setCookie, removeCookie] = useCookies();

    const accessToken: string = useSelector((state: any) => state.user.accessToken)
    /*
    const searchPurchase = (ev: any) => {
        

        setMyPokemon(searched)
        setSearch(true)
    }*/

    const purchased = ()=>{
        if(search !== ""){
            return itemsPurchased.filter((e: Purchase) => {
                return e.product.title.toLowerCase().includes(search)
            })
        }

        return itemsPurchased
    }

    useEffect(() => {
        //console.log(accessToken);

        if (accessToken) {
            Requests.getUserPurchased(cookies['access_token'])
                .then(res => {
                    setItemsPurchased(res)
                })
                .catch(err => console.log(err))
                .finally(() => setDataLoading(false))
        }
    }, [accessToken])

    return (
        <div className='content-wrapper'>
            <h2 className='purchase-history-title'>Storico riscatti</h2>
            <div className='purchase-searchbar'>
                <input ref={searchInput} type="text" onChange={(e)=>{
                    e.preventDefault()
                    let value = e.currentTarget.value
                    setSearch(value)
                }} />
                {
                    search ?
                        <a onClick={() => {
                            setSearch("")
                            if (searchInput.current) searchInput.current.value = ""
                        }}>ANNULLA</a>
                        :
                        <a>CERCA</a>
                }
            </div>
            {
                dataLoading ?
                    <CircleLoading color='#fff' />
                    :
                    <ul className='purchased-list'>
                        {
                            purchased().map((el: Purchase, idx) => {
                                return (
                                    <li className='purchase-item' key={idx}>
                                        
                                        <FontAwesomeIcon icon={faCartShopping} size='xl'/>
                                        
                                        <span>
                                            <p><b>OGGETTO </b></p>
                                            <p>{el.product.title}</p>
                                        </span>
                                        <span>
                                            <p><b>COSTO </b></p>
                                            <p>{el.product.price} Jestoni</p>
                                        </span>
                                        <span>
                                            <p><b>RISCATTATO IL </b></p>
                                            <p>{el.purchased_on}</p>
                                        </span>
                                        <span>
                                            <p><b>ID TRANSAZIONE </b></p>
                                            <p> {el.id}</p>
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
            }
        </div>
    )
}

export default PurchaseHistory