import React, { useEffect } from 'react'
import { Product, User } from '../scripts/types'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSnowflake, faStar } from '@fortawesome/free-solid-svg-icons'
import { setCurrentItem } from '../redux/features/itemsSlice'
import { AdminRequests } from '../scripts/admin_requests'
import { useSelector } from 'react-redux'


interface ShopCardProps {
    product: Product
    userData: User,
    isLoggedIn: boolean
}

function ShopCard({ product, userData, isLoggedIn }: ShopCardProps) {

    const IMAGE_PATH = process.env.REACT_APP_BACKEND_URL + "/api/image"
    const dispatcher = useDispatch()
    const accessToken = useSelector((state: any) => state.user.accessToken)

    useEffect(()=>{
        //console.log(userData);
    },[])

    const freezeUnfreezeItem = (item_id: number, status: boolean) => {
        AdminRequests.freezeUnfreezeItem(
            accessToken,
            item_id,
            status
        )
            .then(res => {
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    return (
        <div className={product.frozen ? "shop-card freezed-card" : "shop-card"} key={product.id}>
            {product.img &&
                <div className="shop-card-image" style={{ backgroundImage: `url("${IMAGE_PATH}/${product.img}")` }}>

                </div>
            }

            {
                product.in_stock &&
                product.in_stock === -1 ? 
                     <img className='infinity-icon-stock' src='img/infinity-128.png' width={24} height={24}/> 
                     :
                     <p className='stock-size'>x{product.in_stock}</p>
            }
            {
                product.redeems_count &&
                product.redeems_count === -1 ? 
                     <img className='infinity-icon-redeems' src='img/infinity-128.png' width={24} height={24}/> 
                     :
                     <p className='redeems-count'>x{product.redeems_count}</p>
            }
            {
                userData.is_admin &&
                <a 
                    onClick={()=>freezeUnfreezeItem(product.id, !product.frozen)}
                    className='admin-freeze-btn'>
                        {product.frozen ? "UNFREEZE" : "FREEZE"}
                </a>
            }
            <div className={!product.img ? "shop-card-metadata metadata-fullwidth" : "shop-card-metadata"}>
                <div className="title">
                    <h3>{product.title}</h3>
                    {
                        product.for_sub &&
                        <span className='only-for-sub'>
                            <FontAwesomeIcon icon={faStar} />
                            <p>ONLY FOR SUB</p>
                        </span>
                    }

                    {
                        product.frozen &&
                        <span className='item-freezed'>
                            <FontAwesomeIcon icon={faSnowflake} />
                            <p>FREEZED</p>
                        </span>

                    }
                </div>

                <p className="description">{product.description}</p>

                <div className="shop-card-info">
                    <p className="price">{product.price}

                        <img className='logo-jestoni' src='img/jestoni.png' />
                    </p>

                    {
                        (!isLoggedIn) ?
                            <a className='not-available-btn'>NON DISPONIBILE</a>

                            :

                            (product.frozen) ?
                                <a className='not-available-btn'>NON DISPONIBILE</a>
                                :
                                (product.for_sub) ?

                                    (
                                        userData.is_subscriber
                                            ?
                                            <a className='buy-btn' onClick={() => {
                                                //setSelectedItem(el)
                                                dispatcher(setCurrentItem(product))
                                            }}>Riscatta</a>
                                            :
                                            <a className='not-available-btn'>NON DISPONIBILE</a>
                                    )
                                    :
                                    <a className='buy-btn' onClick={() => {
                                        //setSelectedItem(el)
                                        dispatcher(setCurrentItem(product))
                                    }}>Riscatta</a>

                    }

                </div>
            </div>
        </div>
    )
}

export default ShopCard