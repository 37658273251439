import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../css/NotFound.scss'
import { faBan } from '@fortawesome/free-solid-svg-icons'

function NotFound() {
  return (
    <div className="content-wrapper">
      <div id="not-found">
        <p id='not-found-title'>
          <FontAwesomeIcon icon={faBan} style={{ marginRight: 12 }} />
          404 NOT FOUND
        </p>
        <p>La pagina richiesta non è stata trovata</p>
      </div>

    </div>
  )
}

export default NotFound