import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import CircleLoading from '../components/CircleLoading';
import _ from 'lodash'
import Requests from '../scripts/requests';
import { useDispatch } from 'react-redux';
import { User } from '../scripts/types';
import { useSelector } from 'react-redux';
import { setUserData } from '../redux/features/userSlice';
import { AdminRequests } from '../scripts/admin_requests';


import "../css/PokemonAdmin.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import PokemonTypeBadge from '../components/PokemonTypeBadge';

function PokemonAdmin() {

    const dispatcher = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies();

    const [dataLoading, setDataLoading] = useState(true)
    const [allPokemon, setAllPokemon] = useState<Array<any>>([])
    const [allPokemonSet, setAllPokemonSet] = useState<Array<any>>([])

    const [usersPokemon, setUsersPokemon] = useState<Array<any>>([])
    const [usersPokemonSet, setUsersPokemonSet] = useState<Array<any>>([])
    const userData: User = useSelector((state: any) => state.user.userData)

    useEffect(() => {
        if (cookies['access_token']) {
            Requests.getUserData(cookies['access_token'])
                .then(res => {
                    dispatcher(setUserData(res))
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setDataLoading(false)
                })


        } else {
            setDataLoading(false)
        }
    }, [])

    useEffect(() => {
        if (cookies['access_token']) {
            AdminRequests.getAllPokemon(cookies['access_token'])
                .then((res) => {
                    setAllPokemon(res)
                    setAllPokemonSet(res)
                })
                .catch(err => console.log(err))
            
            AdminRequests.getAllUsersPokemon(cookies['access_token'])
                .then((res) => {
                    setUsersPokemon(res)
                    setUsersPokemonSet(res)
                })
                .catch(err => console.log(err))
        }
    }, [])


    function searchInPokemonList(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)

        let filtered = allPokemonSet.filter((el, idx) => {
            return el.name.includes(formData.get('search'))
        })

        setAllPokemon(filtered)
    }


    function searchInUsersPokemonList(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)

        let filtered = usersPokemonSet.filter((el, idx) => {
            return formData.get("search-type") === "pokemon" ? 
                el.name.includes(formData.get('search'))
                :
                el.user.includes(formData.get("search"))
        })

        setUsersPokemon(filtered)
        
    }


    function deletePokemon(pokemon_id: number) {
        AdminRequests.deletePokemonFromList(cookies['access_token'], pokemon_id)
            .then(el => {
                const pkm = allPokemon.filter((el: any, idx) => {
                    return el.id !== pokemon_id
                })

                setAllPokemon(pkm)
            })
            .catch(err => console.log(err))
    }

    function deleteUserPokemon(user_id: string, pokemon_id: string) {
        AdminRequests.deletePokemonFromUserList(cookies['access_token'], user_id, pokemon_id)
            .then(el => {
                AdminRequests.getAllUsersPokemon(cookies['access_token'])
                    .then(res => setUsersPokemon(res))
            })
            .catch(err => console.log(err))
    }


    const addNewPokemon = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)

        const insertResult = document.getElementById("insert-result")
        if (insertResult) {
            insertResult.textContent = "loading"
            insertResult.style.color = "goldenrog"
        }

        AdminRequests.addNewPokemon(cookies['access_token'], formData)
            .then((res: any) => {
                const pkm: Array<any> = [...allPokemon]
                pkm.push(res)
                
                setAllPokemon(pkm)

                const insertResult = document.getElementById("insert-result")
                if (insertResult) {
                    insertResult.textContent = "success"
                    insertResult.style.color = "green"
                }
            })
            .catch(err => {
                const insertResult = document.getElementById("insert-result")
                if (insertResult) {
                    insertResult.textContent = "failed"
                    insertResult.style.color = "red"
                }
            })
            .finally(() => {
                setTimeout(() => {
                    const insertResult = document.getElementById("insert-result")
                    if (insertResult) {
                        insertResult.textContent = ""
                    }
                }, 5000)
            })
    }

    const addUserPokemon = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const formData = new FormData(event.currentTarget as HTMLFormElement)

        const insertResult = document.getElementById("pokemon-user-result")
        if (insertResult) {
            insertResult.textContent = "loading"
            insertResult.style.color = "goldenrog"
        }

        AdminRequests.addPokemonToUser(cookies['access_token'], formData)
            .then((res: any) => {
                const pkm: Array<any> = [...usersPokemon]
                pkm.push(res)
                setUsersPokemon(pkm)

                if (insertResult) {
                    insertResult.textContent = "success"
                    insertResult.style.color = "green"
                }
            })
            .catch(err => {
                if (insertResult) {
                    insertResult.textContent = "failed"
                    insertResult.style.color = "red"
                }
            })
            .finally(() => {
                setTimeout(() => {
                    if (insertResult) {
                        insertResult.textContent = ""
                    }
                }, 5000)
            })

    }

    return (
        dataLoading ?
            <CircleLoading color='#fff' />
            :
            _.isEmpty(userData) || !cookies['access_token'] ?
                <h3 style={{ color: "#fff", textAlign: "center" }}>Errore</h3>
                :
                userData.is_admin ?

                    <div className="pokemon-admin-container">
                        <div className="pokemon-list-box">

                            <h3 style={{ textAlign: 'center', color: "goldenrod" }}>LISTA POKEMON</h3>

                            <div className="pokemon-list-admin">


                                <form onSubmit={addNewPokemon} className="pokemon-insert">
                                    <label>Pokemon Name</label>
                                    <input name='name' className="pokemon-input-name" type='text' />

                                    <label>Pokemon Types</label>
                                    <input name='types' className="pokemon-input-type" type='text' />

                                    <button
                                        className="pokemon-insert-btn"
                                        type='submit'
                                    >AGGIUNGI</button>

                                    <p id="insert-result"></p>
                                </form>

                                <form onSubmit={searchInPokemonList} className="pokemon-list-search-form">
                                    <input name='search' type="text" className="pokemon-user-search-text" />
                                    <button type='submit'>CERCA</button>
                                </form>

                            </div>

                            <div className="pokemon-list">
                                <div className="pokemon-item header-bar">
                                    <p className='pokemon-id'>ID</p>
                                    <p className='pokemon-name'>POKEMON</p>
                                    <p className='pokemon-points'>PUNTI</p>
                                    <p className='pokemon-type'>TIPI</p>
                                    <p className="pokemon-commands">AZIONI</p>
                                </div>
                                <div className="pokemon-item">
                                    <p className='pokemon-id'></p>
                                    <p className='pokemon-name'>EMPTY</p>
                                    <p className='pokemon-points'></p>
                                    <p className='pokemon-type'></p>
                                    <p className="pokemon-commands"></p>
                                </div>
                                {
                                    allPokemon.map((el: any, idx) => {
                                        return (
                                            <div className="pokemon-item" key={idx}>
                                                {/*<p className='pokemon-icon'>{}</p>*/}
                                                <p className='pokemon-id'>{el.id}</p>
                                                <p className='pokemon-name'>{el.name}</p>
                                                <p className='pokemon-points'>{el.points}</p>
                                                <p className='pokemon-type'>
                                                    {el.types.split(",").map((e: string) => {
                                                        return <PokemonTypeBadge type={e.toLowerCase()} />
                                                    })}

                                                </p>
                                                <div className="pokemon-commands">
                                                    <FontAwesomeIcon
                                                        icon={faClose}
                                                        onClick={() => deletePokemon(el.id)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>


                        <div className="pokemon-user-box">
                            <h3 style={{ textAlign: 'center', color: "goldenrod" }}>POKEMON UTENTI</h3>

                            <div className="pokemon-user-admin">

                                <form className="pokemon-user-form" onSubmit={addUserPokemon}>
                                    <label className="pokemon-name">Pokemon ID</label>
                                    <input name='pokemon_id' type="text" className="pokemon-name-input" />
                                    <label className="user-name">User</label>
                                    <input name='username' type="text" className="pokemon-user-input" />

                                    <button type='submit' className="pokemon-user-btn">AGGIUNGI</button>

                                    <p id="pokemon-user-result"></p>
                                </form>


                                <form className="pokemon-user-search-form" onSubmit={searchInUsersPokemonList}>
                                    <input name='search' type="text" className="pokemon-user-search-text" />
                                    <button type='submit'>CERCA</button>

                                    <div className="radio-group">
                                        <input defaultChecked id="search-type-1" name="search-type" value="username" type='radio' className='pokemon-user-search-username' />
                                        <label htmlFor='search-type-1'>Username</label>
                                    </div>

                                    <div className="radio-group">
                                        <input id="search-type-2" name="search-type" value="pokemon" type='radio' className='pokemon-user-search-pokemon' />
                                        <label htmlFor='search-type-2'>Pokemon Name</label>
                                    </div>
                                </form>

                            </div>

                            <div className="pokemon-user-list">
                                <div className="user-pokemon header-bar">
                                    <p className="pokemon-user">UTENTE</p>
                                    <p className="pokemon-id">POKEMON ID</p>
                                    <p className="pokemon-name">POKEMON NAME</p>
                                    <div className="pokemon-commands">AZIONI</div>
                                </div>
                                <div className="user-pokemon">
                                    <p className="pokemon-user">EMPTY</p>
                                    <p className="pokemon-id"></p>
                                    <p className="pokemon-name"></p>
                                    <div className="pokemon-commands"></div>
                                </div>
                                {usersPokemon.map((el, idx) => {
                                    return (
                                        <div className="user-pokemon" key={idx}>
                                            <p className="pokemon-user">{el.user}</p>
                                            <p className="pokemon-id">{el.id}</p>
                                            <p className="pokemon-name">{el.name}</p>
                                            <div className="pokemon-commands">
                                                <FontAwesomeIcon
                                                    icon={faClose}
                                                    onClick={() => deleteUserPokemon(el.user_id, el.id)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    :

                    <h3 style={{ color: "#fff", textAlign: "center" }}>Non autorizzato</h3>
    )
}

export default PokemonAdmin