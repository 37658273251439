import React, { useEffect, useState } from 'react'
import { Product, User } from '../scripts/types'
import { useDispatch, useSelector } from 'react-redux'
import "../css/Shop.scss"
import '../css/components/ShopCard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faSnowflake, faStar } from '@fortawesome/free-solid-svg-icons'
import ShopOverlay from '../components/ShopOverlay'
import Requests from '../scripts/requests'
import { getItems, setCurrentItem, setTags } from '../redux/features/itemsSlice'
import CircleLoading from '../components/CircleLoading'
import ShopCard from '../components/ShopCard'
import ShopTag from '../components/ShopTag'

/*
const exampleRewards: ShopCard[] = [
  {
    "title": "Cambia voce",
    "description": "Riscatta questa card e fai cambiare la voce a JustSpeetz per 3 minuti",
    "price": 5500
  },
  {
    "title": "Siciliano",
    "description": "Riscatta questa card e fai parlare JustSpeetz in dialetto siciliano per 5 minuti",
    "price": 8500,
    "forSub": true
  },
  {
    "title": "JustCroccantino",
    "description": "Riscatta questa card per far mangiare a JustSpeetz un croccantino di Loki",
    "price": 1350
  },
  {
    "title": "Buono Amazon 10€",
    "description": "Riscatta questa card per ottenere un buono amazon dal valore di 10€",
    "price": 112500
  },
]
*/
/*
interface ShopProps {
  isSubscriber: boolean
}
*/

function Shop() {

  const dispatcher = useDispatch()
  const items: Product[] = useSelector((state: any) => state.items.items)
  const tags: Product[] = useSelector((state: any) => state.items.tags)
  const currentItem: Product = useSelector((state: any) => state.items.currentItem)
  const isLoggedIn: boolean = useSelector((state: any) => state.user.loggedIn)
  const userData: User = useSelector((state: any) => state.user.userData)
  //const isSubscriber: boolean = useSelector((state: any) => state.user.subscriber)

  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [activeTags, setActiveTags] = useState<Array<string>>([])

  useEffect(() => {

    Requests.getAllShopItems()
      .then(res => {
        dispatcher(getItems(res))
      })
      .catch(err => console.log(err))
      .finally(() => setDataLoading(false))

    Requests.getAllShopTags()
      .then(res => {
        dispatcher(setTags(res))
      })
      .catch(err => console.log(err))
  }, [])

  const addRemoveTags = (tag: string) => {
    if (activeTags.includes(tag)) {
      const newArray = activeTags.filter(item => item !== tag);
      setActiveTags(newArray)
    } else {
      let newArray= [...activeTags]
      newArray.push(tag)
      setActiveTags(newArray)
    }
  }

  return (
    <div className='content-wrapper'>
      {currentItem &&
        <ShopOverlay
          question={`Sicuro di voler riscattare l'oggetto "${currentItem.title}"?`}
        />
      }


      {dataLoading ?
        <div className="empty-container">
          <CircleLoading color='#fff' />
        </div>

        :

        items.length === 0 ?
          <div className="empty-container">
            <h2 className='shop-empty'>Nessun elemento da visualizzare</h2>
          </div>
          :
          <div className='shop-container'>
            <div className="shop-tags">
              {tags.length > 0 &&
                tags.map((el: any, idx: number) => <ShopTag 
                  title={el} 
                  active={activeTags.includes(el)} 
                  setTag={addRemoveTags} 
                  key={idx}
                />)
              }

              {activeTags.length > 0 &&
                <a className='reset-tags' onClick={()=>setActiveTags([])}>
                    <FontAwesomeIcon icon={faClose} color='red' size='2xl'/>
                </a>
              }
            </div>
            <div className="shop-cards-grid">
              {items.filter((el: Product, idx: number) => {
                if (activeTags.length === 0) return true
                else{
                  if(!el.tags) return false
                  let item_tags: Array<string> = el.tags.split(",")
                  return item_tags.some(tag => activeTags.includes(tag))
                }
              }).map((el, idx) => {
                return (
                  <ShopCard
                    product={el}
                    userData={userData}
                    isLoggedIn={isLoggedIn}
                    key={idx}
                  />
                )

              })}
            </div>
          </div>
      }
    </div>
  )
}

export default Shop