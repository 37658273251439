import { createSlice } from "@reduxjs/toolkit";


const userSlice = createSlice({
    name: "user",
    initialState: {
        userData: {
            is_subscriber: false,
            is_admin: false
        },
        loggedIn: false,
        twitchAccessToken: null,
        accessToken: null
    },
    reducers:{
        setTwitchAccessToken: (state, action) => {
            state.twitchAccessToken = action.payload
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },
        /*
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        */

        setUserData: (state, action)=>{
            state.userData = {...state.userData, ...action.payload}
        },
        /*
        setUserSubscribed: (state, action)=>{
            state.subscriber = action.payload
        }*/
    }
})

export const {setUserData, setLoggedIn, setAccessToken, setTwitchAccessToken} = userSlice.actions
export default userSlice.reducer