import React from 'react'

import '../css/Maintenance.scss'

function Maintenance() {

    return (
        <div className='maintenance-wrapper'>
            <div className="maintenance-img">
                <div className="social-links">
                    {process.env.REACT_APP_YOUTUBE &&
                        <a className="youtube" href={process.env.REACT_APP_YOUTUBE} target='_blank'>
                            <img src='/img/social/youtube.png' />
                        </a>
                    }

                    {process.env.REACT_APP_INSTAGRAM &&
                        <a className="instagram" href={process.env.REACT_APP_INSTAGRAM} target='_blank'>
                            <img src='/img/social/instagram.png' />
                        </a>
                    }

                    {process.env.REACT_APP_TWITCH &&
                        <a className="twitch" href={process.env.REACT_APP_TWITCH} target='_blank'>
                            <img src='/img/social/twitch.png' />
                        </a>
                    }

                </div>
            </div>
        </div>
    )
}

export default Maintenance