import pokeball from '../assets/pokeball-loading.png'; // Percorso all'immagine della Poké Ball
import '../css/components/PokeCircleLoading.scss';

const PokeCircleLoading = () => {
  return (
    <div className="poke-circle-loading">
      <img src={pokeball} alt="loading" className="pokeball" />
    </div>
  );
};

export default PokeCircleLoading;
