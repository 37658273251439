import React from 'react'

import '../css/components/ShopTag.scss'

interface ShopTagProps{
    title: string
    active: boolean
    setTag: any
}

function ShopTag({title, active, setTag}: ShopTagProps) {
  return (
    <div className={active ? 'shop-tag shop-tag-active' : 'shop-tag'} onClick={()=>setTag(title)}>
        {title}
    </div>
  )
}

export default ShopTag