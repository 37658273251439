import React from 'react'
import LeaderboardFantapoke from '../components/LeaderboardFantapoke'

function PokemonRanking() {
  return (
    <div className='content-wrapper'>
        <LeaderboardFantapoke />
    </div>
  )
}

export default PokemonRanking