import { useSelector } from 'react-redux'

import '../css/Profile.scss'
import { User } from '../scripts/types'
import Requests from '../scripts/requests'
import { useCookies } from 'react-cookie'
import Scratchcard from '../components/Scratchcard'
import { useEffect, useState } from 'react'
import CircleLoading from '../components/CircleLoading'
import { useDispatch } from 'react-redux'
import { setTwitchAccessToken, setUserData } from '../redux/features/userSlice'
import { base64ToBlob } from '../scripts/utils'


function Profile() {

    //TODO: Aggiungere caricamento asincrono con redux sullo stato dell'utente

    const dispatcher = useDispatch()
    const userData: User = useSelector((state: any) => state.user.userData)
    const accessToken: string = useSelector((state: any) => state.user.accessToken)

    const [loaded, setLoaded] = useState<boolean>(false)
    const [bonus, setBonus] = useState<Blob>(new Blob())
    const [errorBonus, setErrorBonus] = useState<string | null>(null)
    const [cookies, setCookie, removeCookie] = useCookies();

    const logout = () => {
        if (accessToken) {
            Requests.logout(accessToken)
                .finally(() => {
                    removeCookie("user_info")
                    removeCookie("access_token")
                    window.location.href = process.env.REACT_APP_CLIENT_URL!
                })
        }
    }

    const prefetchData = async () => {
        await Requests.getUserData(accessToken)
            .then(res => {
                dispatcher(setUserData(res))
                dispatcher(setTwitchAccessToken(res['access_token']))
            })

        await Requests.getUserBonus(accessToken)
            .then(res => {
                if (res) {
                    if ("content" in res) {
                        if (res['content'] === "completed") {
                            setErrorBonus("Bonus già riscattato!")
                        } else {
                            let image = res['content'];
                            let blob = base64ToBlob(image)
                            setBonus(blob)
                            setErrorBonus(null)
                        }
                    }else{
                        setErrorBonus("Impossibile caricare il bonus...")
                    }
                }
            })
            .catch(() => { })

    }

    const redeemBonus = () => {
        //let ok = confirm('Hai completato la scratchcard! Ricaricamento...');
        if (accessToken) {
            Requests.redeemUserBonus(accessToken)
                .then(res => {
                    alert("Complimenti! Hai vinto")
                    window.location.reload();
                })
        }
    }

    useEffect(() => {
        if (accessToken) {
            prefetchData()
                .finally(() => {
                    setLoaded(true)
                })

        }
    }, [accessToken])

    return (
        <div className='content-wrapper'>
            {!loaded ?
                <CircleLoading color='#fff' />
                :
                <div id="profile-container">

                    <div className="profile-data">

                        <img id='profile-title' src='img/PROFILO.png' alt='news-title' />
                        <div
                            className='profile-image'
                            style={{ backgroundImage: `url("${userData.avatar}")` }}
                        >
                        </div>
                        <ul className='profile-info'>
                            <li>
                                <p>TWITCH ID: </p>
                                <p>{userData.id}</p>
                            </li>
                            <li>
                                <p>NOME VISUALIZZATO: </p>
                                <p>{userData.display_name}</p>
                            </li>
                            <li>
                                <p>USERNAME: </p>
                                <p>{userData.username}</p>
                            </li>
                            <li>
                                <p>BILANCIO ATTUALE: </p>
                                <p>{userData.coins.toFixed(1)}</p>
                            </li>
                            <li>
                                <p>ISCRITTO: </p>
                                <p>{userData.is_subscriber ? "SI" : "NO"}</p>
                            </li>
                            <li>
                                <p>ADMIN</p>
                                <p>{userData.is_admin ? "SI" : "NO"}</p>
                            </li>
                        </ul>

                        <a className="logout-btn" onClick={() => logout()}>LOGOUT</a>
                    </div>

                    <div className="daily-scratchcard">
                        <p id='profile-bonus'>DAILY BONUS</p>
                        {
                            errorBonus ?
                                <p>{errorBonus}</p>
                                :
                                <Scratchcard callbackFunction={redeemBonus} blob={bonus} />
                        }

                    </div>

                </div>
            }
        </div>
    )
}

export default Profile