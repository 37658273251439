import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import Requests from '../scripts/requests';
import { useSelector } from 'react-redux';
import { User } from '../scripts/types';
import { setUserData } from '../redux/features/userSlice';
import CircleLoading from '../components/CircleLoading';

import '../css/PokemonAuction.scss'
import { AdminRequests } from '../scripts/admin_requests';
import PokemonTypeBadge from '../components/PokemonTypeBadge';

interface Pokemon {
    name: string
    image: string
}

function PokemonAuction() {

    const dispatcher = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies();

    const pokemonNameRef: any = useRef()

    const [accessToken, setAccessToken] = useState<string | undefined>()
    const [dataLoading, setDataLoading] = useState(true)
    const [pokemon, setPokemon] = useState<null | Pokemon>()
    const [searchPokemon, setSearchPokemon] = useState<boolean>(false)
    const [pokemonTypes, setPokemonTypes] = useState<string[]>([])
    const [selectedTypes, setSelectedTypes] = useState<string[]>([])

    const [showSearchLabel, setShowSearchLabel] = useState<boolean>(false)
    const [searchError, setSearchError] = useState<boolean>(false)

    const [inGame, setInGame] = useState<boolean>(false)
    const [checkStatus, setCheckStatus] = useState<NodeJS.Timer | null>(null)

    //const userData: User = useSelector((state: any) => state.user.userData)

    useEffect(() => {
        if (cookies['access_token']) {
            Requests.getUserData(cookies['access_token'])
                .then(res => {
                    dispatcher(setUserData(res))
                    setAccessToken(cookies['access_token'])

                    let interval = setInterval(() => {
                        AdminRequests.statusAuction(cookies['access_token'])
                            .then((res) => {
                                //console.log("auction status: " + res.status);
                                setInGame(res.status)
                            })
                            .catch(err => null)

                        setCheckStatus(interval)
                    }, 1000 * 10)
                })
                .catch(err => null)
                .finally(() => {
                    setDataLoading(false)
                })
        } else {
            setDataLoading(false)
        }

        return (() => {
            if (checkStatus) {
                clearInterval(checkStatus)
            }
        })
    }, [checkStatus])


    useEffect(() => {
        if (accessToken) {
            AdminRequests.statusAuction(accessToken)
                .then((res) => {
                    setInGame(res.status)
                })
                .catch(err => null)

            Requests.getPokemonTypes(accessToken)
                .then(res => {
                    setPokemonTypes(res)
                })
                .catch(err => null)
        }

    }, [accessToken])


    const searchPokemonRequest = (e: any) => {        
        if ((e.key === "Enter" || e.type === "click") && accessToken && pokemonNameRef.current.value !== "") {
            setSearchPokemon(true)
            AdminRequests.searchPokemon(accessToken, pokemonNameRef.current.value)
                .then(res => {
                    setPokemon({
                        "name": res.name,
                        "image": res.sprites.front_default
                    })


                    setSearchError(false)
                })
                .catch(e => {
                    console.log(e)
                    setPokemon(null)
                    setSearchError(true)
                })
                .finally(() => {
                    setSearchPokemon(false)
                    setShowSearchLabel(true)
                    setTimeout(() => {
                        setShowSearchLabel(false)
                    }, 5000)
                })
        }
    }

    const selectType = (type: string) => {
        if (selectedTypes.includes(type)) {
            const newArray = selectedTypes.filter((e: string) => e !== type)
            setSelectedTypes(newArray)
        } else {
            const newArray = [...selectedTypes]
            newArray.push(type)
            setSelectedTypes(newArray)
        }
    }

    const startAuction = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        //console.log(formData);
        //console.log(selectedTypes);

        if (accessToken) {
            setDataLoading(true)
            AdminRequests.getPokemon(accessToken, {
                "pokemon_name": formData.get("pokemon_name"),
                "types": selectedTypes.join(",")
            })
                .then(res => {
                    let base = formData.get("base")
                    if (base) {
                        let auctionBase = parseFloat(base.toString())
                        AdminRequests.startAuction(accessToken, auctionBase, res.id)
                            .then(res => {
                                setInGame(true)
                            })
                            .catch(err => console.log(err))
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    setDataLoading(false)
                })
        }
    }

    const cancelAuction = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (accessToken) {
            AdminRequests.cancelAuction(accessToken)
                .then(res => {
                    setInGame(false)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }


    return dataLoading ?
        <div className="in-game">
            <CircleLoading color='#fff' />
        </div>
        :
        inGame ?
            <div className="in-game">
                <p>Asta già attiva</p>
                <button onClick={cancelAuction}>ANNULLA</button>
            </div>

            :
            (
                <div className='pokemon-auction-wrapper'>
                    {
                        <div className="pokemon-info">
                            {
                                searchPokemon ?
                                    <CircleLoading color='#fff' />
                                    :
                                    pokemon ?
                                        <div className="pokemon-info-image" style={{ backgroundImage: 'url(' + pokemon.image + ')' }}></div>
                                        :
                                        <div className="pokemon-info-image" style={{ backgroundImage: 'url("/img/ask.png")' }}></div>

                            }

                            {pokemon && !searchPokemon && <p className='pokemon-info-name'>{pokemon.name}</p>}
                            {pokemon && !searchPokemon && <div>{selectedTypes.map((el, idx) => <PokemonTypeBadge type={el} />)}</div>}
                        </div>
                    }

                    <form className='search-pokemon' onSubmit={startAuction}>
                        <label>Base asta: </label>
                        <input
                            type='number'
                            name='base'
                            defaultValue={1000}
                            placeholder='Inserisci base asta'
                        />
                        <br />
                        <label>Cerca: </label>
                        <input
                            ref={pokemonNameRef}
                            type='text'
                            name='pokemon_name'
                            placeholder='Inserisci nome pokemon'
                            //onKeyDown={searchPokemonRequest}
                        />
                        <a className='search-btn' onClick={searchPokemonRequest} >Cerca</a>
                        {
                            showSearchLabel && (
                                searchError ?
                                    <p className='search-error'>Errore nella ricerca pokemon</p>
                                    :
                                    <p className='search-success'>Pokemon trovato con successo</p>
                            )
                        }

                        <div className="pokemon-types">
                            {pokemonTypes.map((type: string, idx: number) => {
                                return (
                                    <PokemonTypeBadge
                                        type={type}
                                        key={idx}
                                        disabled={!selectedTypes.includes(type)}
                                        onClick={() => selectType(type)}
                                    />
                                )
                            })}
                        </div>

                        {
                            pokemon && selectedTypes.length > 0 &&
                            <input className='auction-start-btn' type='submit' value={"AVVIA ASTA"} />
                        }
                    </form>
                </div>
            )
}

export default PokemonAuction