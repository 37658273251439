import '../css/components/PokemonTeamCard.scss'
import pokeball from '../assets/pokeball-loading.png'
import { Team, TeamPokemon } from '../scripts/types'
import PokemonTypeBadge from './PokemonTypeBadge'

interface PokemonTeamCardProps {
    cardNo: number
    team: Team
}



function PokemonTeamCard({ cardNo, team }: PokemonTeamCardProps) {
    function getTeamNumber() {
        if (cardNo < 10) {
            return "#0" + cardNo
        }

        return "#" + cardNo
    }

    return (
        <div className="pokemon-card">
            <div className="pokemon-card-inner">
                <div className="front">
                    <img src="https://i.ibb.co/PYss3yv/map.png" className="map-img" />
                    <div className="row">

                        <p className='card-number'>team{getTeamNumber()}</p>
                        <img src={pokeball} className='pokeball-badge' />
                    </div>
                    <div className="row card-data">
                        {
                            team.pokemon.map((el: TeamPokemon) => {
                                return (
                                    <div className="pokemon">
                                        <div className="pokemon-image" style={{ backgroundImage: 'url("' + el.image + '")' }}></div>

                                        <p className='pokemon-name'>{el.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="row card-info">
                        <div className="row">
                            <img src={team.avatar} width="56px" />

                            <div className="card-trainer">

                                <p className='card-label'>allenatore</p>
                                <p className='card-trainer-name'>{team.username}</p>
                            </div>

                        </div>

                        <div className="row">
                            <div className="column">

                                <p className='card-label'>VALID TILL</p>
                                <p className='card-date'>10/25</p>
                            </div>
                        </div>


                    </div>

                </div>
                <div className="back">
                    <img src="https://i.ibb.co/PYss3yv/map.png" className="map-img" />
                    <div className="bar"></div>
                    <div className="row card-cvv">
                        {/*<div>
                            <img src="https://i.ibb.co/S6JG8px/pattern.png" />
                        </div>
                        <p>824</p>*/
                        }
                    </div>
                    <div className="row card-summary">

                        {team.pokemon.map((el: TeamPokemon) => {
                            return (
                                <div className='card-summary-row'>
                                    <p className='pokemon-name'>{el.name}</p>
                                    <div className='pokemon-types'>
                                        {el.types.split(",").map((el: string) => <PokemonTypeBadge type={el}/>)}
                                    </div>
                                </div>
                            )
                        })}

                        
                    </div>
                    <div className="row">
                        <p className='signature'>Tutti i diritti sono riservati</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PokemonTeamCard