import React, { useEffect, useState } from 'react'

import '../css/components/ShopOverlay.scss'
import Requests from '../scripts/requests'
import { useDispatch } from 'react-redux'
import { setCurrentItem } from '../redux/features/itemsSlice'
import { useSelector } from 'react-redux'
import { Product, TwitchUserInfo } from '../scripts/types'
import { setUserData } from '../redux/features/userSlice'
import CircleLoading from './CircleLoading'

interface ShopOverlayProps {
  question: string
}

interface PurchaseInfo {
  responseText: string,
  transactionId: string,
  inError: boolean
}


function ShopOverlay({ question }: ShopOverlayProps) {

  const dispatcher = useDispatch()
  const currentItem: Product = useSelector((state: any) => state.items.currentItem)
  const accessToken: string = useSelector((state: any) => state.user.accessToken)

  const [purchase, setPurchase] = useState<PurchaseInfo | null>(null)
  const [dataLoading, setDataLoading] = useState(false)

  function buy() {
    setDataLoading(true)

    Requests.purchaseItem(currentItem.id, accessToken)
      .then(res => {
        Requests.getUserData(accessToken)
          .then(res => {
            dispatcher(setUserData(res))
          })
          .catch(err => console.log(err))
        setPurchase({
          responseText: `Riscatto completato con successo`,
          transactionId: res['transactionId'],
          inError: false
        })
      })
      .catch(err => {
        //console.log(err.response);
        setPurchase({
          responseText: `${err.response.data.error}`,
          transactionId: "jnasjkndjksa",
          inError: true
        })
      })
      .finally(() => setDataLoading(false))
  }

  return (
    <div className='shop-overlay'>
      <div className="popup">
        {dataLoading ?
          <CircleLoading color='var(--primary)'/>
          :
          <>
            <h3 className='question'>{!purchase?.responseText ? question : purchase.responseText}</h3>
            {purchase && !purchase.inError && <p className='transaction-id'>ID Transazione: {purchase?.transactionId}</p>}
            <div className="buttons">
              <a className='cancel' onClick={() => {
                dispatcher(setCurrentItem(null))
                setPurchase(null)
              }} >{purchase ? "Indietro" : "Annulla"}</a>
              {!purchase && <a className='complete' onClick={() => buy()}>Riscatta</a>}
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default ShopOverlay