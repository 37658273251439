import React from 'react'

interface PokemonTypeBadgeProps{
    type: string
    disabled?: boolean
    onClick?: any
}

function PokemonTypeBadge(props: PokemonTypeBadgeProps) {
  return (
    <img onClick={props.onClick} style={{filter: props.disabled ? "grayscale(1)" : ""}} src={'/img/badges/'+props.type.toLowerCase()+".png"} width={48}/>
  )
}

export default PokemonTypeBadge