import React, { useEffect, useState } from 'react'

import "../css/BettingAdmin.scss"
import Requests from '../scripts/requests'
import { User } from '../scripts/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { AdminRequests } from '../scripts/admin_requests'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import CircleLoading from '../components/CircleLoading'
import { setUserData } from '../redux/features/userSlice'
import { generateAlphanumericString } from '../scripts/utils'


interface Answer {
    id: string
    text: string
    quote: number
}

interface Template {
    title: string,
    question: string
    responses: Array<string>
}

function BettingAdmin() {

    const dispatcher = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies();


    const [dataLoading, setDataLoading] = useState(true)
    const [bettingStatus, setBettingStatus] = useState<any>()
    const [noData, setNoData] = useState<boolean>(false)

    const [newAnswers, setNewAnswers] = useState<Array<Answer>>([
        { "id": generateAlphanumericString(), text: "", quote: 1 },
        { "id": generateAlphanumericString(), text: "", quote: 1 },
    ])

    const [closingBet, setClosingBet] = useState<boolean>(false)
    const [closingError, setClosingError] = useState<string>("")
    const [sendingBet, setSendingBet] = useState<boolean>(false)
    const [sendingError, setSendingError] = useState<string>("")
    const [templates, setTemplates] = useState<Template[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)

    const [accessToken, setAccessToken] = useState<string | undefined>()
    const userData: User = useSelector((state: any) => state.user.userData)

    const [counter, setCounter] = useState<number>(5)
    const [timer, setTimer] = useState<NodeJS.Timer | null>(null)

    useEffect(() => {
        if (sendingError || closingError) {
            const interval = setInterval(() => {
                setCounter(t => t - 1)
            }, 1000)

            setTimer(interval)
        }
    }, [sendingError, closingError])


    useEffect(() => {
        if (counter === 0 && timer) {
            clearInterval(timer)
            setSendingError("")
            setSendingBet(false)
            setClosingError("")
            setClosingBet(false)
            setCounter(5)
        }
    }, [counter, timer])

    useEffect(() => {
        if (accessToken) {
            AdminRequests.getBettingTemplates(accessToken)
                .then(res => {
                    console.log(res);
                    setTemplates(res)
                })
                .catch(err => setTemplates([]))
        }
    }, [accessToken])


    useEffect(() => {
        if (cookies['access_token']) {
            Requests.getUserData(cookies['access_token'])
                .then(res => {
                    dispatcher(setUserData(res))
                    setAccessToken(cookies['access_token'])

                    AdminRequests.getBettingStatus(cookies['access_token'])
                        .then(res => {
                            setBettingStatus(res)
                        })
                        .catch(err => console.log(err))
                        .finally(() => {
                            setDataLoading(false)
                        })
                })
                .catch(err => console.log(err))
                .finally(() => {

                })
        } else {
            setDataLoading(false)
        }
    }, [])


    useEffect(() => {
        let interval = setInterval(() => {
            AdminRequests.getBettingStatus(cookies['access_token'])
                .then(res => {
                    console.log("Bet status check... Next in 10sec");
                    setBettingStatus(res)
                })
                .catch(err => setNoData(true))
        }, 10 * 1000)

        return () => {
            clearInterval(interval)
        }
    }, [])


    function splitAnswer(answer: string) {
        var array = answer.split("|")
        return array
    }

    function generateAnswer(answer: string) {
        var array = splitAnswer(answer)
        if (array.length < 2) {
            return answer
        } else {
            return (
                <>
                    <p className='answer-text'>{`${array[0]}`}</p>
                    <p className='answer-odds'>{`quota: ${array[1]}`}</p>
                </>)
        }
    }

    function cancelBet(){
        const confirm = window.confirm("Sicuro di voler annullare la bet?")
        if(confirm && accessToken){
            AdminRequests.cancelBet(accessToken)
            .then(res => {
                window.location.reload()
            })
            .catch(err => alert("Errore in fase di annullamento!"))
        }else{
            alert("Errore in fase di annullamento!")
        }
    }


    function closeBet(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setClosingBet(true)
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        var selected = Array.from(formData.keys());
        //console.log(selected);

        if (selected.length == 0) {
            selected.push("_")
        }

        if (accessToken) {
            AdminRequests.closeBet(accessToken, selected)
                .then(res => {
                    window.location.reload()
                })
                .catch((err) => {
                    setClosingError(err)
                })
        }
    }


    function evaluateFormDataAnswers(formData: FormData) {
        const textQuotePairs = [];

        for (const pair of formData.entries()) {
            const [key, value] = pair;

            if (key.startsWith("text_")) {
                const id = key.slice(5);
                const quoteKey = "quote_" + id;

                if (formData.has(quoteKey)) {
                    const quoteValue = formData.get(quoteKey);
                    if (quoteValue) {
                        textQuotePairs.push({ text: value, quote: parseFloat(quoteValue.toString()) });
                    }
                }
            }
        }

        return textQuotePairs
    }


    function sendNewBet(event: React.FormEvent<HTMLFormElement>) {
        setSendingBet(true)
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        if (!formData.get("question")) {
            var error = "No question found in form"
            console.error(error)
            setSendingError(error)
            return
        }
        var answers = evaluateFormDataAnswers(formData)

        if (answers.length < 2) {
            var error = "Answers in form less than 2"
            console.error(error);
            setSendingError(error)
            return
        }

        var inError = false
        answers.forEach(answer => {
            if (answer.text === "") {
                var error = `One answer have no text`
                console.error(error);
                setSendingError(error)
                inError = true
                return
            }
        })
        if (inError) {
            return
        }

        var payload = {
            "question": formData.get("question"),
            "options": answers
        }

        if (accessToken) {
            AdminRequests.openBet(accessToken, payload)
                .then(res => {
                    window.location.reload()
                })
                .catch((err) => {
                    setSendingError(err.message)
                })
        }

    }

    function addNewAnswer(e: any) {
        e.preventDefault()
        var answers = [...newAnswers]
        answers.push({
            "id": generateAlphanumericString(),
            "text": "",
            "quote": 1
        })
        setNewAnswers(answers)
    }

    function selectTemplate(e: any) {
        e.preventDefault()
        const title = e.currentTarget.value
        const template = templates.find((t: Template) => t.title === title)
        if (!template || title === "Empty") {
            setNewAnswers([
                { "id": generateAlphanumericString(), text: "", quote: 1 },
                { "id": generateAlphanumericString(), text: "", quote: 1 },
            ])
            setSelectedTemplate(null)
        } else {
            const array: Answer[] = []
            template.responses.forEach((r: string) => {
                array.push({ "id": generateAlphanumericString(), text: r, quote: 1 })
            })
            setNewAnswers(array)
            setSelectedTemplate(template)
        }

    }

    return dataLoading ?
        noData ?
            <p>Nessun dato disponibile</p>
            :
            <CircleLoading color='#fff' />
        :

        _.isEmpty(userData) || !accessToken ?
            <h3 style={{ color: "#fff", textAlign: "center" }}>Errore</h3>
            :

            userData.is_admin ?
                (
                    <div className='bet-wrapper'>
                        {bettingStatus['status'] ?
                            <form onSubmit={closeBet}>
                                <p className='bet-title'>CHIUDI BET</p>
                                <p className='bet-question'>{bettingStatus['bet']['question']}</p>
                                <ul className="bet-answers">
                                    {bettingStatus['bet']['answers'].split(",").map((el: any, idx: number) => {
                                        return (
                                            <li className='bet-answer' key={idx}>
                                                <input type="checkbox" id="checkbox_id" name={splitAnswer(el)[0]} />
                                                <span className='bet-answer-label'>{generateAnswer(el)}</span>
                                            </li>
                                        )
                                    })
                                    }
                                </ul>

                                {closingBet ?
                                    closingError ?
                                        <p className='closing-error'>Errore nella chiusura della bet - Aggiornamento in {counter}...</p>
                                        :
                                        <p>In chiusura</p>
                                    :
                                    <>
                                        <input id='close-bet-btn' type='submit' value="CHIUDI BET" />
                                        <button id='cancel-bet-btn' type='button' value="ANNULLA BET" onClick={()=>cancelBet()}>ANNULLA</button>
                                    </>
                                    
                                }

                            </form>
                            :
                            <form onSubmit={sendNewBet} className='bet-form'>
                                <p className='bet-title'>APRI BET</p>
                                <fieldset className='bet-form-section-col'>
                                    <span className="bet-form-section">
                                        <label>Domanda</label>
                                        <input
                                            className='bet-question-input'
                                            type='text'
                                            name='question'
                                            defaultValue={selectedTemplate ? selectedTemplate.question : ""}
                                        />
                                    </span>

                                    <span className='bet-form-section'>
                                        <button
                                            onClick={addNewAnswer}
                                        >AGGIUNGI RISPOSTA</button>

                                        {
                                            templates.length > 0 &&
                                            <select onChange={selectTemplate}>
                                                <option>Empty</option>
                                                {templates.map((template: Template, idx: number) => {
                                                    return <option key={idx}>{template.title}</option>
                                                })}
                                            </select>
                                        }
                                    </span>
                                </fieldset>
                                <fieldset className='bet-form-section-col'>
                                    {
                                        newAnswers.map((el: Answer) => {
                                            return (
                                                <span className='bet-new-answer' key={el.id}>
                                                    <label className='text'>testo:</label>
                                                    <input className="text-input" type='text' name={"text_" + el.id} defaultValue={el.text} />
                                                    <label className='quote'>quota:</label>
                                                    <input
                                                        className='quote-input'
                                                        type='number' defaultValue={1}
                                                        name={"quote_" + el.id}
                                                        step="0.01" min="1"
                                                    />
                                                    <FontAwesomeIcon icon={faTrashCan} onClick={() => {
                                                        var answers = [...newAnswers].filter((e: Answer) => e.id !== el.id)
                                                        setNewAnswers(answers)
                                                    }} />
                                                </span>
                                            )
                                        })
                                    }
                                </fieldset>

                                {sendingBet ?
                                    sendingError ?
                                        <p className='sending-error'>{sendingError} - Aggiornamento in {counter}...</p>
                                        :
                                        <p>Invio in corso...</p>
                                    :
                                    <input type='submit' value={"NUOVA BET"} />
                                }


                            </form>

                        }
                    </div>
                )
                :
                <h3 style={{ color: "#fff", textAlign: "center" }}>Non autorizzato</h3>
}




export default BettingAdmin