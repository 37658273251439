import { createSlice } from "@reduxjs/toolkit";
import Requests from "../../scripts/requests";


const itemsSlice = createSlice({
    name: "items",
    initialState: {
        items: [],
        tags: [],
        currentItem: null
    },
    reducers: {
        getItems: (state, action) => {
            state.items = action.payload
        },

        setTags: (state, action) => {
            state.tags = action.payload
        },

        setCurrentItem: (state, action) => {
            state.currentItem = action.payload
        }
    }
})

export const { getItems, setCurrentItem, setTags } = itemsSlice.actions
export default itemsSlice.reducer