import './App.css';

import {
  Outlet
} from "react-router-dom";
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import { useSelector } from 'react-redux'

import "./css/Global.scss"
import LivePopup from './components/LivePopup';
import { useEffect, useState } from 'react';
import ComingSoon from './routes/ComingSoon';
import Maintenance from './routes/Maintenance';
import Requests from './scripts/requests';
import CircleLoading from './components/CircleLoading';


function App() {

  const inLive = useSelector((state: any) => state.channel.inLive)
  const [maintenance, setMaintenance] = useState<boolean>(false)
  const [authorized, setAuthorized] = useState<boolean>(true)
  const [comingSoon, setComingSoon] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    
    Requests.getMaintenanceStatus()
      .then(res => {
        let state = res['status']

        if (state === "maintenance" || state === "preview") {
          Requests.getPublicIP()
          .then(res => { 
            Requests.checkWhitelist(res)
            .then(res => setAuthorized(true))
            .catch(() => setAuthorized(false))
            .finally(() => {
              if (state === "maintenance") {
                setMaintenance(true)
                setComingSoon(false)
              } else {
                setComingSoon(true)
                setMaintenance(false)
              }
            })
          })
          .catch(err => {
            console.log("Unable to get client public IP")
          })
          
        } else {
          setMaintenance(false)
          setComingSoon(false)
          setAuthorized(true)
        }

      })
      .catch(() => {
        setMaintenance(false)
        setComingSoon(true)
        setAuthorized(false)
      })
      .finally(() => setLoading(false))
      
  }, [])

  return loading ?
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <CircleLoading color='#fff' />
    </div>
    :
    !authorized ?
      <>
        {maintenance &&
          <Maintenance />}

        {comingSoon &&
          <ComingSoon />}
      </>
      :
      (
        <div className="app-wrapper">
          <Header />
          <Outlet />
          {inLive && <LivePopup />}
          <Footer />
        </div>
      );
}

export default App;
