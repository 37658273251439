import axios from "axios";

const BACKEND = process.env.REACT_APP_BACKEND_URL

export default class Requests {

    // POKEMON

    static async getPokemonUserScores(access_token: string, username: string) {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/scores/" + username, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        return response.data
    }

    static async getTeams(access_token: string) {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/teams",{
            headers:{
                "Authorization":"Bearer " + access_token
            }
        })
        return response.data
    }

    static async getPokemonScores() {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/scores")
        return response.data
    }

    static async getFantaPokemonStatus(access_token: string) {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/status", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        return response.data
    }

    static async getPokemonTypes(access_token: string) {
        let result = await axios.get(BACKEND + "/api/pokemon/types", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async getUserPokemonTeam(access_token: string) {
        let result = await axios.get(BACKEND + "/api/pokemon/user/team", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async removePokemonFromTeam(pokemon_id: number, access_token: string) {
        let result = await axios.delete(BACKEND + "/api/pokemon/user/team/" + pokemon_id, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async addPokemonToTeam(pokemon_id: number, access_token: string) {
        let result = await axios.post(BACKEND + "/api/pokemon/user/team/" + pokemon_id, null, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async addPokemonToTeamSlot(pokemon_id: number, slot: number, access_token: string) {
        let result = await axios.post(BACKEND + "/api/pokemon/user/team/" + pokemon_id + "/" + slot, null, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async getUserPokemon(access_token: string) {
        let result = await axios.get(BACKEND + "/api/pokemon/user", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }


    // USER

    static async logout(access_token: string) {
        let result = await axios.get(BACKEND + "/auth/twitch/logout", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return true
        }

        return false
    }

    static async validateToken(access_token: string) {

        let result = await axios.get(BACKEND + "/auth/twitch/validate", {
            withCredentials: true,
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })

        if (result.status === 201 || result.status === 200) {
            return result.data
        }

        return false
    }

    static async getSchedule() {
        let result = await axios.get(BACKEND + "/api/schedule")
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async getUserData(access_token: string) {
        let result = await axios.get(BACKEND + "/api/users/info", {
            headers: { "Authorization": "Bearer " + access_token }
        })
        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async getUserBonus(access_token: string) {
        try {
            let result = await axios.get(BACKEND + "/api/users/bonus", {
                headers: { "Authorization": "Bearer " + access_token }
            })
            if (result.status === 200) {
                return result.data
            }
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 400)
                    return { "status": "redeemed" }

                throw err
            }
            throw err
        }

        return null
    }

    static async redeemUserBonus(access_token: string) {
        let result = await axios.post(BACKEND + "/api/users/bonus", {}, {
            headers: { "Authorization": "Bearer " + access_token }
        })

        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async getUserPurchased(access_token: string) {
        let result = await axios.get(BACKEND + "/api/shop/purchased", {
            headers: { "Authorization": "Bearer " + access_token }
        })
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async getAllShopItems() {
        let result = await axios.get(BACKEND + "/api/shop")
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async getAllShopTags() {
        let result = await axios.get(BACKEND + "/api/shop/tags")
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async purchaseItem(item_id: number, access_token: string) {
        let result = await axios.post(BACKEND + "/api/shop/buy/" + item_id, {}, {
            headers: { "Authorization": "Bearer " + access_token }
        })
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async getAllAnnouncements() {
        let result = await axios.get(BACKEND + "/api/news")
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async getMaintenanceStatus() {
        let result = await axios.get(BACKEND + "/api/whitelist/status")
        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async checkWhitelist(ip_address: string) {
        let result = await axios.get(BACKEND + `/api/whitelist?ip=${ip_address}`)
        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async getLeaderboard() {
        let result = await axios.get(BACKEND + `/api/users/leaderboard/all`)
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    // EXTERNAL    
    static async getPublicIP() {
        let endpoint = process.env.REACT_APP_PUBLIC_IP

        if (endpoint) {
            let result = await axios.get(endpoint)
            if (result.status === 200) {
                return result.data['origin']
            }
        }

        return null
    }
}