import React, { useEffect, useRef, useState } from 'react'

import "../css/Admin.scss"
import Requests from '../scripts/requests'
import { Announcement, Product, Purchase, User } from '../scripts/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faL, faPencil } from '@fortawesome/free-solid-svg-icons'
import { AdminRequests } from '../scripts/admin_requests'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import CircleLoading from '../components/CircleLoading'
import { dateFormat } from '../scripts/utils'
import { setUserData } from '../redux/features/userSlice'

function Admin() {

    const dispatcher = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies();


    const [dataLoading, setDataLoading] = useState(true)
    const [items, setItems] = useState<Product[]>([])
    const [schedule, setSchedule] = useState<any>([])
    const [scheduleLoading, setScheduleLoading] = useState<boolean>(false)
    const [announcements, setAnnouncements] = useState([])

    const redeemsSearchRef = useRef<any>()
    const [redeems, setRedeems] = useState<Purchase[]>([])
    const [redeemsSearch, setRedeemsSearch] = useState<null | Purchase[]>(null)

    const [accessToken, setAccessToken] = useState<string | undefined>()
    const userData: User = useSelector((state: any) => state.user.userData)

    useEffect(() => {
        Requests.getSchedule()
            .then(res => {
                setSchedule(res)
            })
            .catch(err => console.log(err))
            .finally()

        Requests.getAllShopItems()
            .then(res => {
                //console.log(res);
                setItems(res)
            })
            .catch(err => console.log(err))
            .finally()
        
            
        Requests.getAllAnnouncements()
            .then(res => {
                //console.log(res);
                setAnnouncements(res)
            })
            .catch(err => console.log(err))
            .finally()

    }, [])

    useEffect(() => {
        if (accessToken) {
            AdminRequests.getAllRedeems(accessToken)
                .then(res => {
                    setRedeems(res)
                })
                .catch(err => console.log(err))

        }
    }, [accessToken])

    useEffect(() => {
        if (cookies['access_token']) {
            Requests.getUserData(cookies['access_token'])
                .then(res => {
                    dispatcher(setUserData(res))
                    setAccessToken(cookies['access_token'])
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setDataLoading(false)
                })
        } else {
            setDataLoading(false)
        }
    }, [])


    const updateSchedule = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)

        setScheduleLoading(true)
        if (accessToken) {
            AdminRequests.updateSchedule(accessToken, formData)
                .then(res => {
                    Requests.getSchedule()
                        .then(res => {
                            setSchedule(res)
                        })
                        .catch(err => {
                            console.log(err);
                        })
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setScheduleLoading(false)
                })
        }
    }

    const searchRedeems = (ev: any) => {
        let searched = redeems.filter((e: Purchase) => {
            return e.product.title.toLowerCase().includes(ev.target.value.toLowerCase()) ||
                e.username.toLowerCase().includes(ev.target.value.toLowerCase())
        })

        setRedeemsSearch(searched)
    }

    const payRedeem = (e: Purchase) => {
        const userConfirmed = window.confirm("Sei sicuro di voler pagare l'oggetto " + e.product.title + "?");

        if (accessToken) {
            if (userConfirmed) {
                console.log("L'utente ha confermato.");
                AdminRequests.payRedeem(accessToken, e.id)
                    .then(res => {
                        AdminRequests.getAllRedeems(accessToken)
                            .then(res => {
                                setRedeems(res)
                            })
                        window.alert(res.message)
                    })
                    .catch(err => window.alert(err.response.data.error))
            }
        } else {
            window.alert("Non autorizzato, riprova")
        }
    }



    return dataLoading ?
        <div className="loading-wrapper">
            <CircleLoading color='#fff' />
        </div>
        :

        _.isEmpty(userData) || !accessToken ?
            <h3 style={{ color: "#fff", textAlign: "center" }}>Errore</h3>
            :

            userData.is_admin ?
                (
                    <>
                        <div className="admin-section">
                            <h3 className="admin-section-title">REEDEMS</h3>

                            <ul className='redeems-table'>
                                <li className='redeems-search-bar'>
                                    <input
                                        ref={redeemsSearchRef}
                                        type='text'
                                        placeholder='Cerca per nome oggetto o utente'
                                        onChange={searchRedeems}
                                    />
                                    {redeemsSearch &&
                                        <a
                                            className='redeems-search-abort'
                                            onClick={() => {
                                                redeemsSearchRef.current.value = ""
                                                setRedeemsSearch(null)
                                            }}
                                        >ANNULLA</a>
                                    }
                                </li>
                                <li className='redeems-list-header'>
                                    <p>Oggetto</p>
                                    <p>Prezzo</p>
                                    <p>Utente</p>
                                    <p>Data Acquisto</p>
                                    <p>Stato</p>
                                    <p>Azione</p>
                                </li>
                                <div className="redeems-items">
                                    {
                                        redeemsSearch ?
                                            redeemsSearch.map((el: Purchase, idx: number) => {
                                                return (
                                                    <li className='redeems-item' key={idx}>
                                                        <p>{el.product.title}</p>
                                                        <p>{el.product.price}</p>
                                                        <p>{el.username}</p>
                                                        <p>{el.purchased_on}</p>
                                                        <p>{el.paid ? "pagato" : "da pagare"}</p>

                                                        <p>
                                                            {!el.paid && <button onClick={() => payRedeem(el)}>PAGA</button>}
                                                        </p>

                                                    </li>
                                                )
                                            })
                                            :
                                            redeems.map((el: Purchase, idx: number) => {
                                                return (
                                                    <li className='redeems-item' key={idx}>
                                                        <p>{el.product.title}</p>
                                                        <p>{el.product.price}</p>
                                                        <p>{el.username}</p>
                                                        <p>{el.purchased_on}</p>
                                                        <p>{el.paid ? "pagato" : "da pagare"}</p>
                                                        <p>
                                                            {!el.paid && <button onClick={() => payRedeem(el)}>PAGA</button>}
                                                        </p>
                                                    </li>
                                                )
                                            })

                                    }
                                </div>
                            </ul>
                        </div>

                        <hr />
                        <div className="admin-section">
                            <h3 className="admin-section-title">SCHEDULE</h3>
                            <div className="admin-section-controls">
                                {scheduleLoading ?
                                    <CircleLoading color='#fff' />
                                    :

                                    <ul id='schedule-admin'>
                                        {schedule &&
                                            Object.keys(schedule["schedule"]).map((el: any, idx) => {
                                                return (
                                                    <li key={idx}>{el} | {schedule["schedule"][el]["title"]} | {schedule["schedule"][el]["time"]}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                }

                                {schedule &&
                                    <form id='schedule-form' onSubmit={updateSchedule}>
                                        {schedule &&
                                            Object.keys(schedule["schedule"]).map((el: any, idx) => {
                                                return (
                                                    <div className="schedule-day-form" key={idx}>
                                                        <label htmlFor={el}>{el}</label>
                                                        <input type='text' defaultValue={schedule["schedule"][el]["title"]} name={el} />
                                                        <label htmlFor={el + '_time'}>Ora</label>
                                                        <input type='text' defaultValue={schedule["schedule"][el]["time"]} name={el + '_time'} />
                                                    </div>
                                                )
                                            })
                                        }
                                        <button type='submit'>Aggiorna</button>
                                    </form>}
                            </div>

                        </div>

                        <hr />
                        <div className="admin-section">
                            <h3 className="admin-section-title">COMUNICAZIONI</h3>
                            <ul className="admin-section-controls">
                                <AnnouncementsForm
                                    accessToken={accessToken}
                                    setAnnouncements={setAnnouncements} />

                                <ul className="admin-section-list announcements-list">
                                    {
                                        announcements.reverse().map((el, idx) => {
                                            return (
                                                <AnnouncementContent
                                                    key={idx}
                                                    idx={idx}
                                                    el={el}
                                                    setAnnouncements={setAnnouncements}
                                                    accessToken={accessToken}
                                                />
                                            )
                                        })
                                    }
                                </ul>
                            </ul>
                        </div>

                        <hr />

                        <div className="admin-section">
                            <h3 className="admin-section-title">ITEMS</h3>

                            <div className="admin-section-controls">

                                <ItemsForm accessToken={accessToken} setItems={setItems} />

                                <ul className="admin-section-list items-list">
                                    {
                                        items.reverse().map((el, idx) => {
                                            return (
                                                <ItemContent
                                                    key={idx}
                                                    idx={idx}
                                                    el={el}
                                                    setItems={setItems}
                                                    accessToken={accessToken}
                                                />
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                        </div>
                    </>
                )
                :
                <h3 style={{ color: "#fff", textAlign: "center" }}>Non autorizzato</h3>
}

interface AnnouncementsFormProps {
    accessToken: string
    setAnnouncements: any
}

function AnnouncementsForm({ accessToken, setAnnouncements }: AnnouncementsFormProps) {

    interface formDataType { [key: string]: FormDataEntryValue }
    const responseBody: formDataType = {}

    const sendAnnouncement = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        console.log(formData);

        AdminRequests.addNewAnnouncement(accessToken, formData)
            .then(res => {
                Requests.getAllAnnouncements()
                    .then(res => setAnnouncements(res))
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <form className="admin-section-form" onSubmit={sendAnnouncement}>
            <label htmlFor='title'>Titolo</label>
            <input type='text' name='title' />
            <label htmlFor='desc'>Descrizione</label>
            <input type='text' name='desc' />
            <label htmlFor='content'>Contenuto</label>
            <input type='text' name='content'></input>
            <label htmlFor='date'>Data</label>
            <input type='date' name='date'></input>

            <button type='submit'>Inserisci</button>
        </form>
    )
}


interface AnnouncementContentProps {
    idx: number
    el: Announcement
    accessToken: string
    setAnnouncements: any
}

function AnnouncementContent({ accessToken, idx, el, setAnnouncements }: AnnouncementContentProps) {

    const [update, setUpdate] = useState(false)

    const updateItem = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        console.log(formData);

        AdminRequests.updateAnnouncement(accessToken, el.id, formData)
            .then(res => {
                Requests.getAllAnnouncements()
                    .then(res => {
                        setAnnouncements(res)
                        setUpdate(false)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    }

    return update ?
        <li>
            <form onSubmit={updateItem}>
                <label htmlFor='title'><b>TITOLO </b></label>
                <input type='text' name='title' defaultValue={el.title} />

                <label htmlFor='desc'><b>DESC </b></label>
                <textarea name='desc' defaultValue={el.description}></textarea>

                <label htmlFor='content'><b>CONTENT </b></label>
                <textarea name='content' defaultValue={el.content}></textarea>

                <label htmlFor='date'>Data</label>
                <input type='date' name='date' defaultValue={el.date}></input>

                <br />

                <button type='submit'>Aggiorna</button>
                <button type='button' onClick={() => { setUpdate(false) }}>Annulla</button>
            </form>

        </li>
        :
        (
            <li key={idx}>
                <p><b>TITOLO </b> {el.title}</p>
                <p><b>DESC </b>{el.description}</p>
                <p><b>CONTENT </b><br />{el.content}</p>
                <p><b>DATA </b><br />{el.date}</p>

                <div className="item-buttons">
                    <FontAwesomeIcon
                        icon={faPencil}
                        onClick={() => {
                            setUpdate(true)
                        }}
                    />
                    <FontAwesomeIcon
                        icon={faClose}
                        color='red'
                        onClick={() => {
                            AdminRequests.removeAnnouncement(accessToken, el.id)
                                .then(res => {
                                    Requests.getAllAnnouncements()
                                        .then(res => {
                                            setAnnouncements(res)
                                        })
                                })
                                .catch(err => console.log(err))
                        }}
                    />
                </div>

            </li>
        )
}


interface ItemsFormProps {
    accessToken: string
    setItems: any
}

function ItemsForm({ accessToken, setItems }: ItemsFormProps) {

    interface formDataType { [key: string]: FormDataEntryValue }
    const responseBody: formDataType = {}

    const sendItem = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        console.log(formData);

        AdminRequests.addNewItem(accessToken, formData)
            .then(res => {
                Requests.getAllShopItems()
                    .then(res => setItems(res))
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <form className="admin-section-form" onSubmit={sendItem}>
            <label htmlFor='title'>Titolo</label>
            <input type='text' name='title' />

            <label htmlFor='desc'>Descrizione</label>
            <input type='text' name='desc' />

            <label htmlFor='command'>Comando</label>
            <input type='text' name='command'></input>

            <label htmlFor='tags'>Tags</label>
            <input type='text' name='tags'></input>

            <label htmlFor='price'>Prezzo</label>
            <input type='number' name='price' defaultValue={1000}></input>

            <label htmlFor='in_stock'>In magazzino</label>
            <input type='number' name='in_stock' defaultValue={-1}></input>

            <label htmlFor='redeems_count'>Riscatti per utente</label>
            <input type='number' name='redeems_count' defaultValue={-1}></input>

            <label htmlFor='only_sub'>Solo sub</label>
            <input type='checkbox' name='only_sub' defaultChecked={false}></input>

            <label htmlFor='img'>Immagine</label>
            <input type='file' name='img'></input>

            <button type='submit'>Inserisci</button>
        </form>
    )
}



interface ItemContentProps {
    idx: number
    el: Product
    setItems: any
    accessToken: string
}

function ItemContent({ idx, el, setItems, accessToken }: ItemContentProps) {

    const IMAGE_PATH = process.env.REACT_APP_BACKEND_URL + "/api/image"
    const [update, setUpdate] = useState(false)

    const updateItem = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        console.log(formData);

        AdminRequests.updateItem(accessToken, el.id, formData)
            .then(res => {
                Requests.getAllShopItems()
                    .then(res => {
                        setItems(res)
                        setUpdate(false)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    }

    return update ?
        <li>
            <form onSubmit={updateItem}>
                <label htmlFor='title'><b>TITOLO </b></label>
                <input type='text' name='title' defaultValue={el.title} />

                <label htmlFor='desc'><b>DESC </b></label>
                <textarea name='desc' defaultValue={el.description}></textarea>

                <label htmlFor='price'><b>PREZZO </b></label>
                <input type='number' name='price' defaultValue={el.price}></input>

                <label htmlFor='only_sub'><b>SOLO SUB </b></label>
                <input type='checkbox' name='only_sub' defaultChecked={el.for_sub}></input>

                <label htmlFor='in_stock'><b>IN MAGAZZINO</b></label>
                <input type='number' name='in_stock' defaultValue={el.in_stock}></input>

                <label htmlFor='redeems_count'><b>RISCATTI UTENTE</b></label>
                <input type='number' name='redeems_count' defaultValue={el.redeems_count}></input>

                <label htmlFor='command'><b>COMANDO </b></label>
                <input type='text' name='command' defaultValue={el.command}></input>

                <label htmlFor='tags'><b>TAGS</b></label>
                <input type='text' name='tags' defaultValue={el.tags}></input>

                <label htmlFor='img'>Immagine</label>
                <input type='file' name='img'></input>

                <br />

                <button type='submit'>Aggiorna</button>
                <button type='button' onClick={() => { setUpdate(false) }}>Annulla</button>
            </form>

        </li>
        :
        (
            <li key={idx}>
                <p><b>TITOLO </b> {el.title}</p>
                <p><b>DESC </b>{el.description}</p>
                <p><b>PREZZO </b>{el.price}</p>
                {el.command && <p><b>COMANDO </b> {el.command}</p>}
                <p><b>MAGAZZINO </b> {el.in_stock === -1 ? "infiniti" : el.in_stock}</p>
                <p><b>RISCATTI </b> {el.redeems_count === -1 ? "infiniti" : el.redeems_count}</p>
                <p><b>SOLO SUB </b>{el.for_sub ? "SI" : "NO"}</p>
                <p><b>TAGS </b>{el.tags}</p>
                {el.img &&
                    <img src={`${IMAGE_PATH}/${el.img}`} width={128} />
                }

                <div className="item-buttons">
                    <FontAwesomeIcon
                        icon={faPencil}
                        onClick={() => {
                            setUpdate(true)
                        }}
                    />
                    <FontAwesomeIcon
                        icon={faClose}
                        color='red'
                        onClick={() => {
                            AdminRequests.removeItem(accessToken, el.id)
                                .then(res => {
                                    Requests.getAllShopItems()
                                        .then(res => {
                                            setItems(res)
                                        })
                                })
                                .catch(err => console.log(err))
                        }}
                    />
                </div>

            </li>
        )
}

export default Admin