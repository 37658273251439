import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './routes/Home';
import Profile from './routes/Profile';
import Shop from './routes/Shop';
import PurchaseHistory from './routes/PurchaseHistory';
import Admin from './routes/Admin';
import PokemonAdmin from './routes/PokemonAdmin';
import BettingAdmin from './routes/BettingAdmin';
import BoxPokemon from './routes/BoxPokemon';
import PokemonAuction from './routes/PokemonAuction';
import PokemonScores from './routes/PokemonScores';
import FantaPokemon from './routes/FantaPokemon';
import PokemonRanking from './routes/PokemonRanking';
import NotFound from './routes/NotFound';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    children:[
      {
        path: "*",
        element: <NotFound />,
      }
    ]
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/shop",
        element: <Shop />
      },
      {
        path: "/fantapoke",
        element: <Outlet />,
        children: [
          {
            path: "box",
            element: <BoxPokemon />
          },
          {
            path: "ranking",
            element: <PokemonRanking />
          },
          {
            path: "map",
            element: <FantaPokemon />
          },
        ]
      },      
      {
        path: "/purchase-history",
        element: <PurchaseHistory />
      },
    ]
  },
  {
    path: "/admin",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <Admin />
      },
      {
        path: "pokemon",
        element: <PokemonAdmin />
      },
      {
        path: "pokemon_auction",
        element: <PokemonAuction />
      },
      {
        path: "pokemon_scores",
        element: <PokemonScores />
      },
      {
        path: "betting",
        element: <BettingAdmin />
      }
    ]
  }
])

root.render(
  <CookiesProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </CookiesProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
